import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Drainage"
      description="We offer a complete service, from excavation to installation of reliable sewer and stormwater pipes, manholes and systems designed to meet your specific needs."
      image={"/drainage.jpg"}
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">Drainage</h1>

          <p>
            We offer a complete service, from excavation to installation of
            reliable sewer and stormwater pipes, manholes and systems designed
            to meet your specific needs. Our team of licensed drainlayers have a
            well-established track record in the construction and maintenance of
            both public and private drainage systems.
          </p>
          <ul>
            <li>Sewer mains</li>
            <li>Stormwater mains</li>
            <li>Soak pits</li>
            <li>Large Diameter Drainage Works</li>
            <li>Pump Stations</li>
            <li>Maintenance and upgrades of existing infrastructure</li>
            <li>Polyethylene (PE) pipe welding</li>
            <li>New connections</li>
            <li>Subdivisions</li>
            <li>Sewer mains Dewatering</li>
          </ul>
          <p>
            <Link className="btn btn-primary mt-4" to="/contact/">
              Free quote
            </Link>
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
        <StaticImage
          aspectRatio={12 / 15}
          transformOptions={{ cropFocus: "center" }}
          src="../images/drainage.jpg"
          alt="digging a trench"
          loading="eager"
        />
      </div>
    </div>
  </Layout>
);

export default Concrete;
